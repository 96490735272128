import React,{useState} from 'react'
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import "./Geniesoft.css";
import {Link} from "react-router-dom";
const Geniesoft = ( ) => {
  const {t} = useTranslation();
  const handleClick =(e) => {
    i18next.changeLanguage(e.target.value);
  }
const [old, setOld] = useState(false);
const [newAm , setNewItem] = useState(" ");
const newItem = (event) => {
  setNewItem(event.target.value);
}
const changleHandler = () => {
setOld(true);
}
  return (
    <div>
          <div className="main-wrapper">
              <div className="row auth-wrapper gx-0">
                      <div className="col-lg-4 col-xl-4 bg-info auth-box-2 on-sidebar fixed">
                          <div className="h-100 d-flex align-items-center justify-content-center">
                              <div className="row justify-content-center text-center">
                                  <div className="col-md-7 col-lg-12 col-xl-11">
                                      <div>
                                        <img src="/img/logo1.png" style={{width:"60%"}}></img>
                                      </div>
                                      <h2 className="text-white mt-4 fw-weight-medium about px-3">We are here to IT Solution with 20 years of experience</h2>
                                      <p className="op-5 text-white fs-4 mt-4 achieve px-2">
                                      To achieve total customer satisfaction through cutting edge and innovative software solutions which enhance today’s systems and support tomorrow’s needs.
                                      </p>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="col-lg-8 col-xl-8 d-flex align-items-center justify-content-center">
                              {old ? 
                                  (<div className="row justify-content-center w-100 mt-4 mt-lg-0">
                                        <div className="col-lg-6 col-xl-6 col-md-7">
                                              <div className="card" id="loginform">
                                                    <div className="card-body">
                                                        <div>
                                                              <div class="logo text-center">
                                                                  <img src="/img/logo1.png" style={{width:"50%"}}></img>
                                                                  <h6 class="mb-4 mt-3 text-dark text-center px-3">{t("welcome")}<br/><span className='text-info font'>{newAm}</span> !!!</h6>
                                                              </div>
                                                              <div class="row mt-4">
                                                                    <div class="col-12">
                                                                        <div class="text-dark1 px-3">
                                                                        {t("decription")}
                                                                        </div>
                                                                        <form class="form-horizontal" action="index.html">
                                                                        
                                                                            <div class="checkbox checkbox-primary mt-4 mb-3">
                                                                                <input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                value=""
                                                                                id="r-me1"
                                                                                required
                                                                              />
                                                                                  <label className="form-check-label ms-2" for="r-me1"> {t("term")}</label>
                                                                            </div>
                                                                            <div class="d-flex align-items-stretch">
                                                                                <Link to="/about" class="btn btn-info d-block w-100">{t("genie")}</Link>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                              </div>
                                                        </div>
                                                    </div>
                                          </div>
                                        </div>
                                </div>)
                                : ( <div className="row justify-content-center w-100 mt-4 mt-lg-0">
                                        <div className="col-lg-6 col-xl-5 col-md-7">
                                            <div className="card" id="loginform">
                                                <div className="card-body">
                                                    <h2 className='text-center'>Welcome to genieSoft System</h2>
                                                    <p className="text-center text-muted fs-4">
                                                      New Here?
                                                        <a className="ms-2" href="#" id="to-register">Create an account</a>
                                                    </p>
                                                    <form className="form-horizontal mt-4 pt-4 needs-validation" novalidate action="index.html" onSubmit={changleHandler}>
                                                        <div class="input-group mb-4">
                                                            <span class="input-group-text" id="basic-addon1">@</span>
                                                            <input type="text" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" required onChange={newItem}/>
                                                        </div>
                                                          <div class="input-group mb-5">
                                                              <label class="input-group-text" for="inputGroupSelect01">Languages</label>
                                                              <select required class="form-select" id="inputGroupSelect01" onChange={(e)=> handleClick(e)}>
                                                                  <option value={""} selected>Choose...</option>
                                                                  <option value={"en"}>English</option>
                                                                  <option value={"hi"}>Hindi</option>
                                                                  <option value={"ma"}>Marathi</option>
                                                                  <option value={"gu"}>Gujrati</option>
                                                              </select>
                                                          </div>
                                                          
                                                          <div className="d-flex align-items-center mb-3">
                                                              <div className="form-check">
                                                                  <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    value=""
                                                                    id="r-me1"
                                                                    required
                                                                  />
                                                                  <label className="form-check-label" for="r-me1">Remember me</label>
                                                                  <div className="invalid-feedback">
                                                                    You must agree before submitting.
                                                                  </div>
                                                              </div>
                                                              <div className="ms-auto">
                                                                <a href="#" id="to-recover" className="btn btn-ouyline-primaryfw-bold me-2" onClick={()=> handleClick('chi')}>Reset?</a>
                                                              </div>
                                                          </div>
                                                          <div class="d-flex align-items-stretch">
                                                            <button type="submit" class="btn btn-info d-block w-100">login In</button>
                                                          </div>
                                                        </form>
                                                </div>
                                        </div>   
                                      </div>
                            </div>)} 
                      </div>
              </div>
        </div>
    </div>
  )
}
export default Geniesoft;

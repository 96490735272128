import React,{useState} from 'react'; 
import "./Geniesoft.css";
import {Link} from "react-router-dom";
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
export const About = (props) => {
    const {t} = useTranslation();
  const handleClick =(e) => {
    i18next.changeLanguage(e.target.value);
  }
  const [newAm , setNewItem] = useState(" ");
const newItem = (event) => {
  setNewItem(event.target.value);
}
  return (
    <div>
        <div className="main-wrapper">
            <div className="row auth-wrapper gx-0">
                <div className="col-lg-4 col-xl-4 bg-info auth-box-2 on-sidebar">
                    <div className="h-100 d-flex justify-content-center align-items-center">
                        <div className="row justify-content-center text-center side">
                            <div className="col-md-7 col-lg-12 col-xl-11">
                                <div>
                                    <img src="/img/logo1.png" style={{width:"60%",borderRadius:"8px"}}></img>
                                </div>
                                <h2 className="text-white mt-4 fw-weight-medium about px-3">We are here to IT Solution with 20 years of experience</h2>
                                <p className="op-5 text-white fs-4 mt-4 achieve px-2">
                                To achieve total customer satisfaction through cutting edge and innovative software solutions which enhance today’s systems and support tomorrow’s needs.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-xl-4 bg-info auth-box-2 on-sidebar side1">
                    <div className="h-100 d-flex justify-content-center align-items-center">
                        <div className="row justify-content-center text-center">
                            <div className="col-md-7 col-lg-12 col-xl-11">
                                <div>
                                    <img src="/img/logo1.png" style={{width:"60%",borderRadius:"8px"}}></img>
                                </div>
                                <h2 className="text-white mt-4 fw-weight-medium about px-3">We are here to IT Solution with 20 years of experience</h2>
                                <p className="op-5 text-white fs-4 mt-4 achieve px-2">
                                To achieve total customer satisfaction through cutting edge and innovative software solutions which enhance today’s systems and support tomorrow’s needs.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-8 col-xl-8 d-flex align-items-center justify-content-center">
                <div className="row justify-content-center w-100 mt-4 mt-lg-0">
                <div className="col-lg-9 col-xl-9 col-md-7">
                <div className='card mt-5 mb-5 upper' style={{border:"2px solid #ccc"}}>
                <div className='card-body'>
                <div class="logo text-center">
                <img src="/img/logo1.png" style={{width:"30%"}}></img>
                <h6 class="mb-5 mt-1 text-center text px-3"> {t("welcome")}<span className='text-info font' onChange={props.onChange}>{newAm}</span> !!!</h6>
            </div>
                   <div className='row py-3'>
                       <div className='col-xl-6 col-lg-6 col-sm-12'>
                               <div className='card transform'style={{border:"1px solid #ccc"}}>
                               <div className='card-body p-0'>
                               <div className='messsage2'>
                               <img src="/img/image 2.png" className="message2" style={{width:"100%"}}></img>
                               </div>
                               <div className='d-flex justify-content-between align-items-center'>
                               <span><i class='fas fa-arrows-alt-h ps-2' style={{fontSize:"48px",color:"red"}}></i></span>
                               <h6 className='card-title text-center p-3'>{t("forward")}</h6>
                               </div>
                               </div>
                               </div>
                           </div>
                       <div className='col-xl-6 col-lg-6 col-sm-12 d-flex justify-content-center align-items-center'>
                       <div className='card transform' style={{border:"1px solid #ccc"}}>
                       <div className='card-body p-0'>
                       <div className='messsage2'>
                       <img src="/img/confrence.png" className="message2" style={{width:"100%"}}></img>
                       </div>
                       <div className='d-flex justify-content-between align-items-center'>
                               <span><i class='fas fa-long-arrow-alt-left ps-2' style={{fontSize:"48px",color:"red"}}></i></span>
                               <h6 className='card-title text-center p-3'>{t("left")}</h6>
                        </div>
                       </div>
                       </div>
                       </div>
                   </div>
                  
                   <div className='row py-3'>
                   <div className='col-xl-6 col-lg-6 col-sm-12'>
                           <div className='card transform'style={{border:"1px solid #ccc"}}>
                           <div className='card-body p-0'>
                           <div className='messsage2'>
                           <img src="/img/image 1.png" className="message2" style={{width:"100%"}}></img>
                           </div>
                           <div className='d-flex justify-content-between align-items-center'>
                           <span><i class='fas fa-long-arrow-alt-up ps-2' style={{fontSize:"48px",color:"red"}}></i></span>
                           <h6 className='card-title text-center p-3'>{t("straight")}</h6>
                     </div>
                           </div>
                           </div>
                       </div>
                   <div className='col-xl-6 col-lg-6 col-sm-12 d-flex justify-content-center align-items-center'>
                   <div className='card transform' style={{border:"1px solid #ccc"}}>
                   <div className='card-body p-0'>
                   <div className='messsage2'>
                   <img src="/img/washroom1.jpg" className="message2" style={{width:"100%"}}></img>
                   </div>
                   <div className='d-flex justify-content-between align-items-center'>
                               <span className='d-flex'><i class='fas fa-exchange-alt ps-2' style={{fontSize:"48px",color:"red"}}></i></span>
                               <h6 className='card-title text-center p-3'>{t("right")}</h6>
                        </div>
                  
                   </div>
                   </div>
                   </div>
               </div>

                
                <div className="d-flex align-items-center mb-3">
                <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="r-me1"
                      required
                    />
                    <label className="form-check-label ms-2" for="r-me1">{t("term")}</label>
                    <div className="invalid-feedback">
                      You must agree before submitting.
                    </div>
                </div>
                <div className="ms-auto">
                  <Link to="/" id="to-recover" className="fw-bold me-2">{t("home")}</Link>
                </div>
            </div>
                <div class="d-flex align-items-stretch">
                 <a href="http://geniesoftsystem.com/" id="to-recover" class="btn btn-info d-block w-100">{t("visit")}</a>                                        </div>
                </div>
                </div>
                </div>
        </div>
                </div>
            </div>
    </div>
    </div>
  )
}
